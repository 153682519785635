<bit-simple-dialog>
  <i bitDialogIcon class="bwi bwi-info-circle tw-text-3xl" aria-hidden="true"></i>
  <span bitDialogTitle
    ><strong>{{ "yourAccountsFingerprint" | i18n }}:</strong></span
  >
  <span bitDialogContent>
    {{ data.fingerprint.join("-") }}
  </span>
  <ng-container bitDialogFooter>
    <a
      bitButton
      href="https://help.passageway.id/fingerprint-phrase/"
      target="_blank"
      rel="noreferrer"
      buttonType="primary"
      bitDialogClose
    >
      {{ "learnMore" | i18n }}
      <i class="bwi bwi-external-link bwi-fw" aria-hidden="true"></i>
    </a>
    <button bitButton type="button" buttonType="secondary" bitDialogClose>
      {{ "close" | i18n }}
    </button>
  </ng-container>
</bit-simple-dialog>
